.skill-section {
  flex: calc(50% - 32px);
  width: calc(50% - 32px);
  max-width: calc(50% - 32px);

  &:nth-child(odd) {
    padding-right: 32px;
  }
  &:nth-child(even) {
    padding-left: 32px;
  }
  .title {
    font-family: argentum-sans-regular;
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 27px;
  }

  padding-bottom: 54px;
}

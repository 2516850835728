.home-page {
  width: 100%;
  min-height: 100dvh;
  transition: color 1000ms linear, background-color 1000ms linear;
  .main-image-section {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
}

.home-page-all-project-section {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 1000ms linear;
}

@media all and (max-width: 768px) {
  .home-page-all-project-section {
    padding-bottom: 80px;
  }
}

.top-section {
  display: flex;
  min-height: 635px;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding-left: 250px;
  padding-right: 167px;
  align-items: center;
  .top-section-container {
    max-width: 2048px;
    width: 100%;
    .top-section-sub-title {
      padding-top: 137px;
      font-size: 18px;
      font-family: argentum-sans-light;
      max-width: 80%;
    }

    .top-section-title {
      font-size: 70px;
      padding-top: 20px;
      max-width: 80%;
    }

    .top-section-content {
      padding-top: 63px;
      padding-bottom: 80px;
      .top-section-description {
        font-size: 18px;
        font-family: argentum-sans-light;
      }
    }
  }
}

.top-section-content {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.end {
    justify-content: flex-end;
  }
}

.scroll-down-icon {
  animation: rotation 5s infinite linear;
  width: 148px;
  height: 148px;
  display: block;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media all and (max-width: 768px) {
  .top-section {
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
    min-height: auto;
    .scroll-down-icon {
      display: none;
    }
    .top-section-container {
      max-width: 2048px;
      width: 100%;
      .top-section-title {
        font-size: 35px;
        max-width: 100%;
      }
      .top-section-content.end {
        padding-bottom: 80px;
        padding-top: 0px;
      }
    }
  }
}

.top-navbar {
  .top-navbar-container {
    width: calc(100% - 110px);
    display: flex;
    flex-direction: row;
    margin: 36px 55px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    .top-navbar-menu {
      height: 1rem;
    }
  }
}

.home-link {
  color: inherit;
  text-decoration: inherit;
  font-size: 29;
  font-family: argentum-sans-regular;
  z-index: 10005;
  all: unset;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .home-link {
    z-index: unset;
  }
  .top-navbar {
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 1;
    display: flex;
    height: 72px;
    align-items: center;
    .top-navbar-container {
      width: calc(100% - 48px);
      margin: 14px 24px;
      .logo-icon {
        width: 100px;
      }
    }
  }
}

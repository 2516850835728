.projects-options-list-item-container {
  display: flex;
  flex-direction: column;
  .title {
    font-family: argentum-sans-regular;
    font-size: 18px;
    padding-bottom: 12px;
  }
  .text {
    font-family: argentum-sans-light;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 40px;
  }

  .link {
    all: unset;
    cursor: pointer;
    position: relative;
    width: fit-content;
    font-family: argentum-sans-light;
    font-size: 16px;
    line-height: 28px;
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: lightgray;
    }

    &::after {
      background: black;
      transform: scaleX(0);
      transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
      &::after,
      &::before {
        transform: scaleX(1);
      }
    }
  }
}

.projects-options-list-item-image-container {
  position: relative;
  margin-bottom: 16px;
  aspect-ratio: 446 / 446;
  img {
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    bottom: 0px;
    width: 100%;
  }
}

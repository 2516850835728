.project-about-section {
  display: flex;
  justify-content: center;
  .project-about-section-container {
    max-width: 2024px;
    padding: 220px 250px;
    .project-about-section-grid {
      display: flex;
      .project-about-section-grid-left {
        flex: 2;
        display: flex;
        justify-content: flex-start;
      }
      .project-about-section-grid-right {
        flex: 3;
      }
    }
  }

  .title {
    font-family: argentum-sans-regular;
    font-size: 26px;
  }
  .text {
    all: unset;
    font-family: argentum-sans-light;
    font-size: 22px;
    display: block;
    margin-bottom: 60px;
    line-height: 40px;
  }
  .coming-soon-text {
    font-family: argentum-sans-regular;
    font-size: 20px;
  }
}

@media all and (max-width: 768px) {
  .project-about-section {
    .project-about-section-container {
      padding: 60px 24px;
      .project-about-section-grid {
        flex-direction: column;
        .project-about-section-grid-left {
          padding-bottom: 56px;
        }

        .project-about-section-grid-right {
          display: flex;
          flex-direction: column;
          .text {
            line-height: 32px;
            font-size: 18px;
          }
          .link-button {
            align-self: center;
          }
          .coming-soon-text {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.rolling-link {
  color: inherit;
  text-decoration: inherit;
  font-size: 50px;
  display: inline-block;
  position: relative;
  transform: translate3d(0px, 0px, 0px);
  &:not(:last-child) {
    margin-bottom: 35px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right center;
    background: currentColor;
    transition: transform 0.5s, transform-origin 0s;
  }

  em {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0 0.01em 0 0;
    font-style: normal;
    span {
      display: inline-block;
      color: transparent;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);

      &::before,
      &::after {
        content: attr(data-text);
        display: block;
        position: absolute;
        color: #000;
      }

      &::before {
        top: 0;
        transform: skewY(0);
        transform-origin: right bottom;
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &::after {
        top: 105%;
        transform: skewY(7deg);
        transform-origin: left top;
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  &:hover {
    em {
      span {
        transform: translateY(-105%);
        &::before {
          transform: skewY(7deg);
        }
        &::after {
          transform: skewY(0);
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .rolling-link {
    font-size: 35px;
  }
}

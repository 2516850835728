.projects-options-list {
  display: flex;
  justify-content: center;
  .projects-options-list-wrapper {
    max-width: 2048px;
    width: 100%;
    .projects-options-list-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 100px 250px;

      .all-projects-link {
        &.desktop {
          display: flex;
        }
        &.mobile {
          display: none;
        }
      }

      .projects-options-list-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 66px;
        .title {
          font-family: argentum-sans-regular;
          font-size: 60px;
          line-height: 84px;
        }
      }

      .projects-options-list-projects {
        display: flex;
        justify-content: center;
        width: 100%;
        &.mobile {
          display: none;
        }
        &.desktop {
          display: flex;
        }
      }

      .projects-options-list-item {
        flex: 1 1;
        width: calc(33.3% - 30px);
        &:first-child {
          padding-right: 30px;
        }

        &:last-child {
          padding-left: 30px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .projects-options-list {
    .projects-options-list-wrapper {
      max-width: 100%;
      .projects-options-list-container {
        padding-left: 24px;
        padding-right: 24px;
        overflow: hidden;

        .projects-options-list-heading {
          .title {
            font-size: 35px;
            padding-bottom: 24px;
          }
        }

        .all-projects-link {
          &.desktop {
            display: none;
          }
          &.mobile {
            display: flex;
            margin-top: 50px;
          }
        }

        .projects-options-list-projects {
          &.mobile {
            display: flex;
          }
          &.desktop {
            display: none;
          }
        }

        .projects-options-list-item {
          width: 100%;
          padding: 0 0 50px 0;
          &:first-child,
          &:last-child {
            padding: 0 0 50px 0;
          }
        }
      }
    }
  }
}

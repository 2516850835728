.project-cards-rows-section {
  display: flex;
  justify-content: center;
  .project-cards-rows-section-container {
    .project-card-row {
      max-width: 2048px;
      padding: 100px 250px;
      &:not(:last-child) {
        padding-bottom: 80px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .project-cards-rows-section {
    .project-cards-rows-section-container {
      .project-card-row {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

.skills-list {
  .skill-option {
    &:not(:last-child) {
      padding-bottom: 24px;
    }
  }
  .skill-title {
    font-family: argentum-sans-regular;
    font-size: 18px;
    line-height: 36px;
  }
  .skill-text {
    font-family: argentum-sans-light;
    font-size: 18px;
    line-height: 36px;
    &:not(:last-child) {
      padding-bottom: 52px;
    }
  }
}

.media-section {
  &.desktop-media-section {
    display: block;
  }
  &.mobile-media-section {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .media-section {
    &.desktop-media-section {
      display: none;
      &.force-show {
        display: block;
      }
    }
    &.mobile-media-section {
      display: block;
      &.force-hide {
        display: none;
      }
    }
  }
}

.App {
  background-color: #ffffff;
}

.landscape-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: argentum-sans-regular;
  .icon {
    width: 25%;
  }
  .title {
    font-size: 42px;
    line-height: 50px;
  }
  .text {
    font-size: 24px;
    line-height: 50px;
  }
}

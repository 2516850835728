.project-card {
  .project-card-image {
    &img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  .project-card-content {
    transition: all 0.3ms ease-out;
    .project-card-text {
      font-family: argentum-sans-light;
      font-size: 18px;
      line-height: 34px;
      margin-top: 24px;
    }
  }
}

@media all and (max-width: 768px) {
  .project-card {
    .project-card-content {
      .project-card-text {
        font-size: 18px;
        line-height: 32px;
        margin: 0px;
      }
    }
  }
}

.menu-aside {
  background-color: #ffffff;
  width: 632px;
  z-index: 10001;
  top: 0px;
  bottom: 0px;
  height: 100%;
  position: fixed;
}

.menu-content {
  margin-left: 82px;
  margin-right: 57px;
  margin-bottom: 47px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
}

.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 166px;
}

.menu-column {
  display: flex;
  flex-direction: column;
}

.menu-column-header {
  color: #707070;
  font-size: 24px;
  padding-bottom: 45px;
}

.menu-column-option {
  color: inherit;
  text-decoration: inherit;
  font-size: 50px;
  display: inline-block;
  position: relative;
  transform: translate3d(0px, 0px, 0px);
  &:not(:last-child) {
    margin-bottom: 35px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right center;
    background: currentColor;
    transition: transform 0.5s, transform-origin 0s;
  }

  em {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0 0.01em 0 0;
    font-style: normal;
    span {
      display: inline-block;
      color: transparent;
      transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);

      &::before,
      &::after {
        content: attr(data-text);
        display: block;
        position: absolute;
        color: #000;
      }

      &::before {
        top: 0;
        transform: skewY(0);
        transform-origin: right bottom;
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &::after {
        top: 105%;
        transform: skewY(7deg);
        transform-origin: left top;
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  &:hover {
    em {
      span {
        transform: translateY(-105%);
        &::before {
          transform: skewY(7deg);
        }
        &::after {
          transform: skewY(0);
        }
      }
    }
  }
}

.menu-overlay {
  background-color: #eeeeee;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

.menu-button {
  z-index: 10002;
  margin-left: 28px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: -1.5625rem;
    left: calc((-72px + 1.5625rem) / 2);
    width: 72px;
    height: 72px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    backdrop-filter: blur(2px);
  }
}

.menu-text-button {
  font-size: 19px;
  font-family: argentum-sans-regular;
  cursor: pointer;
  margin-right: 53px;
}

main {
  display: flex;
  flex-direction: row;
}

.email-link {
  font-size: 18px;
  margin-top: 103px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  div {
    color: #707070;
    margin-bottom: 10px;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    padding-bottom: 1px;
    width: fit-content;
    position: relative;
    display: inline-block;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.3);
    }

    &::after {
      background: #000;
      transform: scaleX(0);
      transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
      &::after,
      &::before {
        transform: scaleX(1);
      }
    }
  }
}

.bottom-row {
  margin-bottom: 47px;
  display: flex;
  justify-content: space-between;
}

.social-link {
  cursor: pointer;
  margin-right: 18px;
  svg {
    width: 39px;
    height: 39px;
    transform: scale(1);
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
}

@media all and (max-width: 768px) {
  .menu-button {
    &::before {
      content: none;
    }
  }
  .menu-column-header {
    padding-bottom: 24px;
  }

  .menu-text-button {
    display: none;
  }

  .menu-container {
    margin-top: 24px;
  }

  .menu-aside {
    width: 100%;
    overflow-y: scroll;
  }

  .menu-content {
    margin: 0px;
    padding: 82px 24px 82px 24px;
    .bottom-row {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      .social-links {
        padding-bottom: 48px;
        padding-top: 48px;
      }
    }
  }
}

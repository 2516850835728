.project-cards-section {
  padding: 100px 250px;
  display: flex;
  justify-content: center;
  .project-cards-section-container {
    max-width: 2048px;
    width: 100%;
    .project-cards-section-grid {
      display: flex;
      justify-content: space-between;
      transform: translateY(-46px);
      .project-cards-section-grid-column {
        flex: 1 1;
        &.left-column {
          display: block;
          margin-right: 58px;
        }
        &.right-column {
          display: block;
          margin-top: 110px;
        }
        &.mobile-column {
          display: none;
        }
        .project-card:not(:last-child) {
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .project-cards-section {
    padding-left: 24px;
    padding-right: 24px;

    .project-cards-section-container {
      .project-cards-section-grid {
        transform: none;
        .project-cards-section-grid-column {
          &.left-column,
          &.right-column {
            display: none;
          }

          &.mobile-column {
            display: block;
          }
          .project-card:not(:last-child) {
            padding-bottom: 72px;
          }
        }
      }
    }
  }
}

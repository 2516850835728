.floating-card {
  flex: 21%;
  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease-in-out;
    box-shadow: none;
    &:hover {
      box-shadow: 11px 18px 28px -13px rgba(128, 128, 128, 0.87);
      img {
        opacity: 1;
        filter: grayscale(0);
      }
    }
    img {
      width: 80%;
      filter: grayscale(1);
      opacity: 0.4;
      transition: all 500ms ease-in-out;
    }
  }
}

@media all and (max-width: 768px) {
  .floating-card {
    flex: 50%;
    filter: none;
    .image-container {
      transition: none;
      &:hover {
        filter: none;
        box-shadow: none;
        img {
          opacity: 1;
          transition: none;
        }
      }
      img {
        opacity: 1;
      }
    }
  }
}

.home-page-intro-section {
  width: 100%;
  .home-page-intro-section-container {
    max-width: 2048px;
    .home-page-intro-section-content {
      display: flex;
      flex-direction: column;
      padding-left: 250px;
      padding-right: 167px;
      .home-page-intro-section-title {
        font-size: 50px;
        padding-top: 214px;
        max-width: 80%;
      }

      .home-page-intro-section-description {
        font-family: argentum-sans-light;
        font-size: 22px;
        line-height: 46px;
        padding-bottom: 80px;
        max-width: 80%;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .home-page-intro-section {
    .home-page-intro-section-container {
      .home-page-intro-section-content {
        padding-left: 24px;
        padding-right: 24px;
        .home-page-intro-section-title {
          padding-top: 100px;
          font-size: 35px;
          max-width: 100%;
        }
        .home-page-intro-section-description {
          max-width: 100%;
          padding-bottom: 40px;
          margin: 0px;
          font-size: 18px;
        }
      }
    }
  }
}

.about-section-container {
  display: flex;
  align-items: center;
  padding: 115px 250px;
  justify-content: space-between;
}

.link-buttons {
  display: flex;
  .link-button {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.about-section-content {
  width: 50%;
}

.about-section-wrapper {
  max-width: 2048px;
}

.about-section {
  display: flex;
  justify-content: center;
}

.about-section-social {
  display: flex;
  flex-direction: column;
  .social-link {
    margin: 13px 0px;
  }
  &.mobile {
    display: none;
  }
}

.about-section-title {
  font-size: 50px;
  margin-bottom: 32px;
}

.about-section-text {
  font-size: 22px;
  line-height: 40px;
  margin-bottom: 60px;
  font-family: argentum-sans-light;
}

@media all and (max-width: 768px) {
  .link-buttons {
    display: flex;
    flex-direction: column;
    .link-button {
      &:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 24px;
      }
    }
  }

  .about-section-container {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0px;
  }
  .about-section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-section-text {
    text-align: center;
  }
  .about-section-social {
    &.mobile {
      display: block;
      padding-bottom: 42px;
      .social-link:not(:last-child) {
        padding-right: 36px;
      }
    }
    &.desktop {
      display: none;
    }
  }

  .about-section-title {
    font-size: 35px;
    margin-bottom: 24px;
  }

  .about-section-text {
    font-size: 18px;
    margin-bottom: 42px;
  }
}

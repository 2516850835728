.footer {
  height: 100svh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.footer-container {
  max-width: 2048px;
  width: 100%;
  height: 100%;
}

.footer-content {
  padding: 150px 250px;
  max-width: 100%;
  display: flex;
  color: white;
  flex-direction: column;
  min-height: calc(100% - 300px);
  justify-content: center;
}

.footer-side-sections {
  display: flex;
  justify-content: space-between;
}

.footer-side {
  flex: 1 1 0;
}

.footer-side-left {
  display: flex;
  flex-direction: column;
  .footer-title {
    font-size: 70px;
    margin-bottom: 40px;
  }
}

.footer-side-right {
  display: flex;
  font-weight: 300;
  font-size: 26px;
  margin-top: 70px;
  justify-content: center;
  .footer-link {
    font-family: argentum-sans-light;
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  .footer-links {
    display: flex;
    flex-direction: column;
  }
}

.footer-anchor {
  font-weight: 300;
  font-size: 26px;
  text-decoration: inherit;
  color: white;
  font-family: argentum-sans-light;
}

.footer-link {
  font-weight: 300;
  font-size: 26px;
  text-decoration: inherit;
  em {
    span {
      font-family: argentum-sans-light;

      &::after,
      &::before {
        color: white;
      }
    }
  }
}

.footer-email-link {
  width: fit-content;
  position: relative;
  margin-bottom: 40px;
  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
  }

  &::after {
    background: white;
    transform: scaleX(0);
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    &::after,
    &::before {
      transform: scaleX(1);
    }
  }
}

.footer-bottom {
  margin-top: 200px;
  border-top: 1px solid #707070;
  font-size: 16px;
  padding-top: 14px;
}

@media all and (max-width: 768px) {
  .footer {
    height: calc(100vh - 72px);
    .footer-anchor {
      font-size: 18px;
    }
    .footer-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .footer-content {
        padding: 0px 83px;
      }
      .footer-side-left,
      .footer-side-right {
        text-align: center;
      }
      .footer-side-sections {
        flex-direction: column;
      }
      .footer-bottom {
        margin-top: 55px;
        padding-top: 55px;
        text-align: center;
      }

      .footer-side-left {
        align-items: center;
        .footer-title {
          font-size: 35px;
        }
      }
    }
  }
}

.projects-gallery-item {
  margin-bottom: 182px;
  .projects-gallery-item-container {
    display: flex;
    flex-direction: column;

    .projects-gallery-item-link {
      all: unset;
      cursor: pointer;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
    .projects-gallery-item-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      transition: all 0.3ms ease-out;
      .projects-gallery-item-title {
        font-size: 26px;
      }
      .projects-gallery-item-text,
      .projects-gallery-item-subtitle {
        font-family: argentum-sans-light;
      }

      .projects-gallery-item-subtitle {
        font-size: 18px;
      }

      .projects-gallery-item-text {
        margin-top: 16px;
        line-height: 40px;
        margin-bottom: 24px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .projects-gallery-item {
    .projects-gallery-item-container {
      .projects-gallery-item-content {
        .projects-gallery-item-text,
        .projects-gallery-item-subtitle {
          font-size: 18px;
        }
        .projects-gallery-item-text {
          margin-top: 16px;
          line-height: 32px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

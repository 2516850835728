.projects-gallery {
  display: flex;
  justify-content: center;
}

.projects-gallery-container {
  padding-left: 250px;
  padding-right: 250px;
  display: flex;
  max-width: 2048px;
  width: 100%;
  justify-content: center;
}

.projects-gallery-grid {
  display: flex;
  justify-content: space-between;
  transform: translateY(-46px);
  width: 100%;
}

.projects-gallery-grid-column {
  flex: 1;
  &.right-column {
    margin-top: 110px;
  }
  &.left-column {
    margin-right: 58px;
  }
  &.mobile-column {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .projects-gallery {
    .projects-gallery-container {
      padding-left: 24px;
      padding-right: 24px;
      .projects-gallery-grid-column {
        &.right-column,
        &.left-column {
          display: none;
        }
        &.mobile-column {
          display: block;
        }
      }
    }
  }
}

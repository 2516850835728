.hamburger-button {
  display: block;
  width: 1.5625rem;
  height: 1rem;
  position: fixed;
  right: 55px;

  span {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 0.125rem;
    background-color: #000000;
    border-radius: 2rem;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

    &:nth-of-type(1) {
      top: 0;
      width: 50%;
    }
    &:nth-of-type(2) {
      top: 50%;
    }
    &:nth-of-type(3) {
      top: 100%;
    }
  }

  &:hover {
    span {
      width: 100%;
    }
  }

  &.open {
    span {
      &:nth-of-type(1) {
        transform: rotate(45deg);
        top: 0.5rem;
        width: 100%;
      }
      &:nth-of-type(2) {
        transform: translateX(-100%);
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 0.5rem;
        transform: rotate(-45deg);
      }
    }
  }
}

@media all and (max-width: 768px) {
  .hamburger-button {
    right: 24px;
  }
}

.desktop-media-section {
  position: relative;
  img,
  video {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  max-width: 100%;
  .content-inner {
    aspect-ratio: 1052/ 591;
  }
}

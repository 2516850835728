// .vertical-animated-sections {
//   display: flex;
//   justify-content: center;
// }

.vertical-animated-sections-wrapper {
  position: relative;
  max-width: 2048px;
}

.vertical-animated-sections-sticky-frame {
  transition: border-color 1000ms linear, background-color 1000ms linear;
  width: calc(calc(((100% - 417px) / 2)) + 4px);
  left: calc(calc(250px + (100% - 417px) / 2) - 2px);
  right: 0px;
  height: 0px;
  background: transparent;
  position: sticky;
  overflow: hidden;
  z-index: 1000;
}

.vertical-animated-sections-sticky-frame-top {
  top: 0px;
  border-top-width: 94px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  margin-right: 164px;
}

.vertical-animated-sections-sticky-frame-bottom {
  bottom: 0px;
  border-top-width: 0px;
  border-bottom-width: 94px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  margin-right: 167px;
}

.vertical-animated-sections {
  display: flex;
  justify-content: center;
  width: 100%;
}

.vertical-animated-sections-wrapper {
  width: 100%;
}

.vertical-animated-sections-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 140px;
  padding-right: 140px;
}

.vertical-animated-sections-media-container,
.vertical-animated-sections-content-container {
  min-width: 50%;
}

.vertical-animated-sections-media {
  height: 100%;
}

@media all and (max-width: 768px) {
  .vertical-animated-sections {
    .vertical-animated-sections-container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.project-page {
  .desktop-media-section.mid-media-section {
    max-width: calc(100% - 280px);
    padding: 0px 140px;
    img,
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .content-inner {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.floating-cards-section {
  display: flex;
  max-width: 2048px;
  padding: 100px 250px;
  flex-direction: column;
  align-items: center;
  .cards {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 24px;
  }
  .title {
    font-size: 26px;
    line-height: 36px;
    font-family: argentum-sans-regular;
    padding-bottom: 82px;
  }
}

@media all and (max-width: 768px) {
  .floating-cards-section {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0px;
    .cards {
      row-gap: 0px;
      column-gap: 0px;
    }
    .title {
      padding-bottom: 24px;
    }
  }
}

.vertical-animated-section {
  width: 100%;
  display: flex;
  padding-bottom: 125px;

  .media-content {
    flex: 50%;
  }

  .media-content-image {
    all: unset;
    cursor: pointer;
  }

  .text-content {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 22px;
      font-family: argentum-sans-light;
      padding-bottom: 16px;
      max-width: 80%;
    }
    .text {
      max-width: 80%;
      font-size: 40px;
      padding-bottom: 40px;
      line-height: 46px;
      font-family: argentum-sans-regular;
    }
    .subText {
      max-width: 80%;
      font-size: 18px;
      font-family: argentum-sans-light;
    }

    /* Maximum width */
    @media (max-width: 1980px) {
      .title {
        font-size: 22px;
      }
      .text {
        font-size: 32px;
      }
      .subText {
        font-size: 18px;
      }
    }

    /* Maximum width */
    @media (max-width: 1200px) {
      .title {
        font-size: 18px;
        padding-bottom: 18px;
      }
      .text {
        font-size: 28px;
        padding-bottom: 25px;
      }
      .subText {
        font-size: 16px;
      }
    }
  }

  &[data-order="right"] {
    .text-content {
      order: 2;
      padding-left: 32px;
    }
  }
  &[data-order="left"] {
    .text-content {
      padding-right: 32px;
    }
  }
}

@media all and (max-width: 768px) {
  .vertical-animated-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    .media-content {
      flex: 0 1 auto;
    }
    .text-content {
      flex: 0 1 auto;
      transform: none !important;
      padding-top: 40px;
    }
    &[data-order="right"],
    &[data-order="left"] {
      .text-content {
        order: 2;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

.project-card-row-container {
  display: flex;
  &[data-order="right"] {
    .project-card-row-image {
      order: 2;
    }
    .row-element {
      &:first-child {
        padding-left: 32px;
      }
      &:not(:first-child) {
        padding-right: 32px;
      }
    }
  }
  &[data-order="left"] {
    .row-element {
      &:first-child {
        padding-right: 32px;
      }
      &:not(:first-child) {
        padding-left: 32px;
      }
    }
  }
  .row-element {
    flex: 1 1;
  }

  .project-card-row-text {
    display: flex;
    align-items: center;
    .text {
      padding: 45px;

      font-size: 26px;
      line-height: 46px;
      font-family: argentum-sans-light;
    }
  }
}

@media all and (max-width: 768px) {
  .project-card-row-container {
    flex-direction: column;
    &[data-order="right"],
    &[data-order="left"] {
      .project-card-row-image {
        order: unset;
        padding-bottom: 24px;
      }
      .row-element {
        padding-left: 0px;
        padding-right: 0px;
        .text {
          line-height: 32px;
        }
        &:first-child {
          padding-left: 0px;
          padding-right: 0px;
        }
        &:not(:first-child) {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }

    .project-card-row-text {
      .text {
        padding: 0px;
        font-size: 18px;
      }
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box; /* Not supported in IE 6 & IE 7 */
  scroll-behavior: smooth;
}

[data-scrollbar] {
  display: block;
  position: relative;
}

.scroll-content {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.scrollbar-track {
  position: absolute;
  opacity: 0;
  z-index: 1;
  background: rgba(222, 222, 222, 0.75);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.5s 0.5s ease-out;
  transition: opacity 0.5s 0.5s ease-out;
}
.scrollbar-track.show,
.scrollbar-track:hover {
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.scrollbar-track-x {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
}
.scrollbar-track-y {
  top: 0;
  right: 0;
  width: 8px;
  height: 100%;
}
.scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

@font-face {
  font-family: "argentum-sans-black";
  src: local("ArgentumSans-Black"),
    url("./fonts/ArgentumSans-Black.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-black-italic";
  src: local("ArgentumSans-BlackItalic"),
    url("./fonts/ArgentumSans-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-bold";
  src: local("ArgentumSans-Bold"),
    url("./fonts/ArgentumSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-bold-italic";
  src: local("ArgentumSans-BoldItalic"),
    url("./fonts/ArgentumSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-extra-bold";
  src: local("ArgentumSans-ExtraBold"),
    url("./fonts/ArgentumSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-extra-bold-italic";
  src: local("ArgentumSans-ExtraBoldItalic"),
    url("./fonts/ArgentumSans-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-extra-light";
  src: local("ArgentumSans-ExtraLight"),
    url("./fonts/ArgentumSans-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-extra-light-italic";
  src: local("ArgentumSans-ExtraLightItalic"),
    url("./fonts/ArgentumSans-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-italic";
  src: local("ArgentumSans-Italic"),
    url("./fonts/ArgentumSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-light";
  src: local("ArgentumSans-Light"),
    url("./fonts/ArgentumSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-light-italic";
  src: local("ArgentumSans-LightItalic"),
    url("./fonts/ArgentumSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-medium";
  src: local("ArgentumSans-Medium"),
    url("./fonts/ArgentumSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-medium-italic";
  src: local("ArgentumSans-MediumItalic"),
    url("./fonts/ArgentumSans-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-regular";
  src: local("ArgentumSans-Regular"),
    url("./fonts/ArgentumSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-semi-bold";
  src: local("ArgentumSans-SemiBold"),
    url("./fonts/ArgentumSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-semi-bold-italic";
  src: local("ArgentumSans-SemiBoldItalic"),
    url("./fonts/ArgentumSans-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-thin";
  src: local("ArgentumSans-Thin"),
    url("./fonts/ArgentumSans-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "argentum-sans-thin-italic";
  src: local("ArgentumSans-ThinItalic"),
    url("./fonts/ArgentumSans-ThinItalic.ttf") format("truetype");
}

* {
  font-family: argentum-sans-regular;
}

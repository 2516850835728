.about-me-section {
  .project-card-row-container {
    .project-card-row-text {
      .text {
        padding: 0px;
        all: unset;
        span {
          font-family: argentum-sans-light;
          font-size: 18px;
          line-height: 36px;
          padding-bottom: 32px;
          display: block;
        }
      }
    }
  }
}

.top-section.about-page-top-section {
  min-height: fit-content;
  .top-section-container {
    .top-section-content {
      padding-bottom: 0px;
    }
  }
}

@media all and (max-width: 768px) {
  .about-me-section {
    .project-card-row-container {
      .project-card-row-text {
        .text {
          span {
            line-height: 32px;
          }
        }
      }
    }
  }
}

.basic-button {
  all: unset;
  font-size: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: #707070;
  border-radius: 33px;
  width: 200px;
  height: 45px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  background-color: transparent;
  transition: background-color 0.8s cubic-bezier(0.19, 1, 0.22, 1),
    color 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  &:hover {
    background-color: black;
    color: white;
  }
}

.skills-section-desktop {
  padding: 100px 250px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media all and (max-width: 768px) {
  .skills-section-desktop {
    display: none;
  }
}

.skills-section-mobile {
  padding: 0px 24px 100px 24px;
  display: none;
  justify-content: flex-start;
  flex-wrap: wrap;
  .tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 48px;
    justify-content: space-around;
    .tab {
      font-size: 16px;
      font-family: argentum-sans-light;
      border-radius: 33px;
      padding: 12px;
      background-color: transparent;
      color: black;
      cursor: pointer;
      transition: all 250ms ease-in-out;
      &.selected {
        background-color: black;
        color: white;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .skills-section-mobile {
    display: flex;
  }
}

.title-text-section {
  padding: 100px 250px;
  display: flex;
  justify-content: center;
  .title-text-section-container {
    max-width: 2048px;
    width: 100%;
    .title-text-section-content {
      max-width: 80%;
      .title {
        font-family: argentum-sans-regular;
        font-size: 50px;
      }
      .text {
        margin-top: 32px;
        font-family: argentum-sans-light;
        font-size: 22px;
        line-height: 40px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .title-text-section {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 0px;
    padding-top: 48px;
    .title-text-section-container {
      .title-text-section-content {
        max-width: 100%;
        width: 100%;
        .title {
          font-size: 35px;
        }
        .text {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
}
